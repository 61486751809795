/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"

import "bootstrap/dist/css/bootstrap.min.css"
import "./src/style/cardStats.css"
import "./src/style/scrollButton.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "./src/style/404.css"
import "./src/style/frontPage.css"
import "./src/style/navbar.css"
import "./src/style/About.css"
import "./src/style/services.css"
import "./src/style/detailings.css"
import "./src/style/packages.css"
import "./src/style/contactUs.css"
import "./src/style/CeramicCoating.css"
import "./src/style/reviews.css"
import "./src/style/gallery.css"

import "react-image-gallery/styles/css/image-gallery.css"
// You can delete this file if you're not using it
import Providers from "./src/providers"

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}
